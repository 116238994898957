import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue18/PRZEKLADNIA-GEARO-3-1024x683.jpg';
import pic2 from '../../assets/issue18/ktw-famur.jpg';
import pic3 from '../../assets/issue18/elgor i impact.jpg';
import pic4 from '../../assets/issue18/kalicki2.jpg';
import pic5 from '../../assets/issue18/brasse2.jpg';
import pic6 from '../../assets/issue18/goj2.jpg';
import pic7 from '../../assets/issue18/ksiazek2.jpg';
import pic8 from '../../assets/issue18/obudowa.jpg';

import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'
import bz from '../../assets/issue12/bzawiszowska.jpg'
import tj from '../../assets/issue14/jakubowski.jpg'

export default function Issue2() {

  const _links = [
    {label: "Nowa cena za akcje Grenevia SA", target: "section0"},
    {label: "FAMUR Gearo zaprezentował pierwszą polską przekładnię wiatrową", target: "section1"},
    {label: "Obudowy Famuru trafią do USA", target: "section2b"},
    {label: "Zmiany w zarządzie Projekt Solartechnik", target: "section4"},
    {label: "IMPACT i Elgór + Hansen na targach Solar Energy Expo", target: "section3"},
    {label: "FAMUR zmienił adres. Nowa siedziba w .KTW II", target: "section2"}, 
    {label: "E+H ma nową koordynatorkę ds. ESG", target: "section5"},
   
  ];

  const _title = "Newsletter #1(18), luty 2025";
  const _aside1 =
    <Aside image={bz} theme='light'>
      <p>– Grupa Grenevia kontynuowała swoją działalność, skupiając się na rozwoju czterech segmentów biznesowych, co przełożyło się na wzrost przychodów operacyjnych w każdym z nich. Naszym priorytetem pozostaje tworzenie wartości we wszystkich segmentach, z naciskiem na wspieranie transformacji w kierunku gospodarki niskoemisyjnej – komentuje <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={bz} theme='light'>
      <p>– Umowa zawarta z konsorcjum banków stanowi ważny krok z punktu widzenia dalszego rozwoju Grupy Grenevia. Pozwala nam na zapewnienie średnioterminowego, elastycznego finansowania, które będzie wspierać zarówno tradycyjną część naszego biznesu, jak i zieloną transformację pozostałych segmentów – mówi <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Pierwsza polska przekładnia wiatrowa powstała w FAMUR Gearo</h1>
        <div>
          <p>Duży sukces „wiatrowego oddziału” Famuru, zmiany w zarządzie Projekt Solartechnik i ważne targi, na których wystąpiły Elgór + Hansen oraz Impact Clean Power Technology – to główne wydarzenia od początku roku w naszej Grupie.</p><p>
Zachęcamy do zapoznania się ze szczegółami.
</p>
        </div>
      </Intro>

      <TextContainer id="section0" theme="light">

<h1>Nowa cena za akcje<span className="grText"> Grenevia SA</span></h1>


<p><strong>Zarząd Grenevia SA przekazuje na prośbę swojego większościowego akcjonariusza informację o podwyższeniu ceny w wezwaniu do sprzedaży akcji spółki.</strong></p>
<p>Nowa cena została ustalona na poziomie 2,75 zł za akcję, co stanowi wzrost o 29,7% wobec pierwotnej propozycji wynoszącej 2,12 zł za akcję oraz daje 54,5% premii w stosunku do kursu sprzed dnia ogłoszenia zamiaru wezwania.</p>
<p>Przyjmowanie zapisów na sprzedaż akcji kończy się 28 lutego 2025 r. Wezwanie nie zostanie przedłużone.</p>
<p>Więcej pod linkiem: <a href="https://lp.tdj.pl/wezwanie" target='_blank'>https://lp.tdj.pl/wezwanie</a>.</p>
<p>Dodatkowe informacje dot. procedury składania zapisów w odpowiedzi na wezwania można uzyskać od podmiotu pośredniczącego: <strong>Santander Bank Polska S.A. – Santander Biuro Maklerskie</strong>, 
tel. +48 691 510 168 lub +48 607 082 607.
</p>

</TextContainer>

      <TextContainer id="section1" theme="dark">

<h1>FAMUR Gearo<span className="grText"> zaprezentował pierwszą polską przekładnię wiatrową</span></h1>


<p><strong>FAMUR Gearo – oddział Famuru, którego działalność skupia się na rozwoju technologii dla energetyki wiatrowej – niedawno zaprezentował pierwszą polską przekładnię wiatrową o nazwie GearoX. Gratulujemy!</strong></p>
<h3>✅ Czym jest GearoX?</h3>
<p>GearoX to nowoczesna i uniwersalna przekładnia główna, przeznaczona dla turbin wiatrowych o mocy nominalnej do <strong>2 MW</strong>. Jest kompatybilna z modelami renomowanych producentów, takich jak <strong>Vestas</strong> i <strong>Siemens Gamesa</strong>, i może być wykorzystywana w instalacjach lądowych.</p>
<h3>⚙️ Postęp technologiczny</h3>
<p>FAMUR od ponad 40 lat projektuje, produkuje i remontuje przekładnie zębate przeznaczone dla wielu gałęzi przemysłu.</p>
<p>Stworzenie GearoX to dowód na konsekwentne działania zespołu FAMUR Gearo, który przez ostatni rok rozwijał swoje kompetencje w specjalistycznej dziedzinie energetyki wiatrowej. FAMUR Gearo zapowiada kontynuację prac nad kolejnymi projektami w obszarze przekładni przemysłowych i energetyki wiatrowej.</p>
<Youtube src='Va0Js5gTbqU' />


</TextContainer>


<TextContainer id="section2b" theme="light">

<h1>Obudowy<span className="grText"> Famuru trafią do USA</span></h1>

<p><strong>Amerykańska firma Consol Energy potwierdziła na początku stycznia przyjęcie oferty Famuru na dostawę 285 sztuk sekcji obudowy zmechanizowanej wraz z wyposażeniem dodatkowym. Wartość transakcji wynosi ok. 273 mln zł.</strong></p>
<Image pic={pic8} />

<p>Obudowa trafi do kopalni węgla energetycznego Enlow Fork w Pensylwanii, we wschodniej części Stanów Zjednoczonych.</p>
<p>To już drugi kontrakt na dostawę obudów zmechanizowanych do USA Famuru.</p>
</TextContainer>

<TextContainer id="section4" theme="dark">

<h1>Zmiany <span className="grText">w zarządzie Projekt Solartechnik</span></h1>
<p><strong>Zarząd Projekt Solartechnik działa od lutego w powiększonym składzie. Nowym prezesem spółki został Artur Kalicki. Witamy w zespole PST! Natomiast stanowiska wiceprezesów objęli Błażej Brasse i Adam Goj. Na swoich stanowiskach pozostają bez zmian wiceprezes ds. finansowych Lucjan Augustyn oraz wiceprezes ds. operacyjnych Michał Bernhard. </strong></p>
<p>Zapraszamy do zapoznania się z sylwetkami nowych członków zarządu PST SA.</p>
<Image pic={pic4} />
<h3>Artur Kalicki, prezes zarządu</h3>
<p>Od kilkunastu lat związany z rynkiem energii i energii odnawialnej. Ekspert w zakresie wdrażania innowacji, rozwoju biznesu, planowania i zarządzania strategicznego. Branżowe doświadczenie zdobywał, kierując i nadzorując projekty inwestycyjne z obszaru instalacji biogazowych oraz energetyki słonecznej. W ostatnich latach, w Grupie TAURON, rozwijał obszary związane z fotowoltaiką oraz elektromobilnością. Pełnił funkcję eksperta oceniającego w programach akceleracyjnych ukierunkowanych na współpracę ze start-upami przy współpracy z CVC. Członek zarządu spółek takich jak Polenergia eMobility oraz TAURON Zielona Energia.</p>

<Image pic={pic5} />
<h3>Błażej Brasse, wiceprezes zarządu</h3>
<p>Od początku swojej kariery związany z branżą fotowoltaiczną, zdobywał szerokie doświadczenie w zarządzaniu projektami i rozwoju technologii OZE. Współzałożyciel Projekt Solartechnik Development, spółki odpowiedzialnej za pozyskiwanie gruntów pod budowę farm wiatrowych i fotowoltaicznych, skupowanie i rozwijanie projektów OZE oraz procedowanie uzgodnień koniecznych do budowy farm, którą rozwijał od 2019 r. Był Project Managerem, następnie objął stanowisko dyrektora technicznego, od 2023 roku pełnił funkcję wiceprezesa PST Development, odpowiadając za techniczną stronę procesu developmentu oraz zarządzanie portfelem projektów. Obecnie awansował na stanowisko wiceprezesa w Projekt Solartechnik SA.</p>

<Image pic={pic6} />
<h3>Adam Goj, wiceprezes zarządu</h3>
<p>Podobnie jak poprzednicy, od lat związany z branżą fotowoltaiczną, konsekwentnie rozwijając swoją karierę w ramach grupy Projekt Solartechnik od 2015 roku. Współzałożyciel spółki Projekt Solartechnik Development, w latach 2019-2023 r. prokurent. Zawodową karierę rozpoczynał jako doradca techniczno-handlowy, następnie pełnił funkcję kierownika, a później dyrektora handlowego, łącząc wiedzę techniczną z umiejętnościami w zakresie zarządzania i sprzedaży. Na początku 2023 roku objął stanowisko wiceprezesa PST Development, koncentrując się na procesach związanych z pozyskiwaniem terenów inwestycyjnych i obrotem projektami OZE. Obecnie został powołany do funkcji wiceprezesa w Projekt Solartechnik SA. </p>

 </TextContainer>


     

        <TextContainer id="section3" theme="light">

<h1>IMPACT i Elgór + Hansen<span className="grText"> na targach Solar Energy Expo</span></h1>
<p><strong>Spółki IMPACT Clean Power Technology oraz Elgór + Hansen wzięły w połowie stycznia udział w Solar Energy Expo, największych w Polsce targach poświęconych energii odnawialnej, które odbyły się w Ptak Warsaw Expo. Tegoroczna edycja zgromadziła 315 wystawców oraz przyciągnęła ponad 20 tysięcy odwiedzających.</strong></p>
<Image pic={pic3} />
<p>Solar Energy Expo to kluczowe wydarzenie dla profesjonalistów poszukujących nowoczesnych rozwiązań w dziedzinie energii słonecznej i OZE. Targi stanowią platformę wymiany wiedzy i doświadczeń pomiędzy producentami, dystrybutorami, projektantami i inwestorami, oferując szeroką gamę technologii – od paneli fotowoltaicznych, systemów magazynowania energii, po nowoczesne rozwiązania w zakresie elektromobilności.</p>
<p>Na wspólnym stanowisku IMPACT oraz E+H zaprezentowano m.in. baterie UVES, mobilny magazyn energii oraz ofertę stacji transformatorowych.</p>
<p>Podczas wydarzenia wystąpił również Bartek Kras, wiceprezes IMPACT, który podzielił się swoją wiedzą na temat przyszłości magazynowania energii.</p>
 </TextContainer>

 <TextContainer id="section2" theme="dark">

        <h1>FAMUR<span className="grText"> zmienił adres. Nowa siedziba w .KTW II</span></h1>
       
        <p><strong>Na początku stycznia FAMUR przeniósł swoją główną siedzibę do biurowca .KTW II w centrum Katowic. Nowe biuro ma niemal 1,5 tys. mkw., a nowoczesną przestrzeń wykorzystuje około 90 pracowników.</strong></p>
        <Image pic={pic2} />
  
        <p>Jak podkreśla prezes zarządu FAMUR SA <strong>Tomasz Jakubowski</strong>, zmiana lokalizacji była odpowiedzią na potrzeby zespołu, ponieważ infrastruktura poprzedniego biura nie pozwalała na wdrożenie oczekiwanych udogodnień.</p><p> –<em> Naprzeciw naszym oczekiwaniom wyszły biurowce .KTW. Lokalizacja w sercu Katowic, wysoki standard budynku, a także szereg innych walorów funkcjonalnych, które tworzą nowoczesne miejsce pracy, sprawiły, że w pierwszych dniach stycznia niemal dziewięćdziesiąt osób z zespołu FAMUR zameldowało się na 17. piętrze w .KTW II. Jestem przekonany, że nasi pracownicy docenią zalety budynku i będą się w nim czuli komfortowo</em> – podkreśla prezes Jakubowski.</p>
        <p>FAMUR dołączył tym samym do grona marek z portfela TDJ SA, które zdecydowały się na ulokowanie swoich siedzib w kompleksie .KTW. Wcześniej na ten krok zdecydowały się już TDJ Estate, PST, PGO oraz Grenevia.</p>
        
        
        </TextContainer>
 
 <TextContainer id="section5" theme="light">

<h1>E+H<span className="grText"> ma nową koordynatorkę ds. ESG</span></h1>
<p><strong>Nową osobą pełniącą obowiązki koordynatora ds. ESG w spółce Elgór + Hansen jest Ewelina Książek. Życzymy wielu sukcesów!</strong></p>
<Image pic={pic7} />
<p>Ewelina pracuje w E+H od ponad 20 lat. Od początku zajmowała się kwestiami bezpieczeństwa i higieny pracy, potem również tematami związanymi ze środowiskiem. Pomagała wdrożyć w tej spółce zintegrowany system zarządzania w zakresie norm ISO 14001 oraz 18001 (teraz 45001). Obecnie rozszerza swoje kompetencje o tematy związane z ESG.</p>
<p>– <em>Dlaczego ESG? Ponieważ pomaga firmom działać w sposób bardziej odpowiedzialny wobec środowiska, społeczeństwa i zarządzania. Pomaga poprawić relacje między innymi z pracownikami i lokalnymi społecznościami. Coraz więcej osób zdaje sobie sprawę z wpływu działalności gospodarczej na środowisko. Ludzie cenią firmy, które dbają o swoich pracowników, klientów i społeczności lokalne. W takiej organizacji po prostu lepiej się pracuje</em> – mówi Ewelina.</p>
 </TextContainer>

    <SiteFooter />

    </>
  )
}
